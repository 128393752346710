export enum RouteName {
  HOME = 'assets-home',
  IMPORT = 'assets-import',
  EXPORT = 'assets-export',
  EXPORTS_TABLE = 'assets-exports-table',
  DETAILS = 'asset-details',
  EDIT = 'asset-edit',
  COPY = 'asset-copy',
}

export enum QueryKey {
  ASSET = 'asset',
  ASSETS = 'assets',
  ASSETS_FILTERED = 'assets-filtered',
  ASSET_COMMENTS = 'asset-comments',
  ASSET_HISTORY = 'asset-history',
  LINKED_ISSUES = 'linked-issues',
  ATTACHMENTS = 'asset-attachments',
  EXPORTS = 'assets-exports',
  IMPORTS = 'assets-imports',
  IDS_CHECK = 'assets-ids-check',
}

export enum ExportQueryParams {
  ASSET_TYPES = 'assetTypes',
  LOCATIONS = 'locations',
  ASSET_FIELDS = 'assetFields',
}

export enum ImportType {
  CREATE = 'create',
  UPDATE = 'update',
  CREATE_UPDATE = 'create_update',
}

export enum ImportStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  FAILED = 'failed',
}

export enum ColumnKey {
  ID = 'id',
  NAME = 'name',
  ASSET_TYPE = 'asset_type',
  LOCATION = 'location',
  LOANEE = 'loanee',
}
