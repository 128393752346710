import type { App } from 'vue';
import type { Router } from 'vue-router';
import { initializePostHog } from './posthog/initialize';
import { initializeLogRocket } from './logrocket/initialize';
import { initializeSentry } from './sentry/initialize';
import type { DetailsResponse } from '../auth/types/details';
import { identifyPostHog } from './posthog/identify';
import { identifyLogRocket } from './logrocket/identify';
import { identifySentry } from './sentry/identify';
import { identifyHelpScout } from './helpscout/identify';
import { initializeHelpScout } from './helpscout/initialize';

export const initializeMonitoring = (app: App, router: Router) => {
  if (import.meta.env.DEV) return;

  const posthog = initializePostHog(router);
  initializeLogRocket();
  initializeSentry(app, router, posthog);
  initializeHelpScout();
};

export const identifyMonitoring = (data: DetailsResponse) => {
  if (import.meta.env.DEV) return;

  identifyPostHog(data);
  identifyLogRocket(data);
  identifySentry(data);
  identifyHelpScout(data);
};
