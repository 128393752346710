<script setup lang="ts">
import { useCurrentUser } from '@/modules/auth/composables/useCurrentUser';

const { atlassianId } = useCurrentUser();
</script>

<template>
  <launchnotes-embed
    token="public_7ifxgfMBHmS76KoGtJCkjfnj"
    project="pro_U4keYqXzXeSSk"
    toggle-selector="#whats-new-link"
    heading="Product updates"
    heading-color="#FFF"
    subheading="The latest from the AMFJ team"
    subheading-color="rgba(255, 255, 255, 0.8)"
    primary-color="linear-gradient(135deg, rgb(58, 126, 228) 0%, rgb(22, 78, 162) 100%)"
    widget-placement="auto"
    widget-offset-skidding="0"
    widget-offset-distance="0"
    show-unread
    :anonymous-user-id="atlassianId"
    unread-placement="right-start"
    unread-offset-skidding="0"
    unread-offset-distance="12"
    unread-background-color="#F29272"
    unread-text-color="#0b2a70"
  />
</template>
