import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { api } from '../api';
import { QueryKey } from '../constants';
import type { SettingsRequest } from '../types';
import { useFlagsStore } from '@/modules/common/store';
import { DEFAULT_STALE_TIME_IN_MS } from '@/modules/common/constants/queries';
import type { Ref } from 'vue';

const SUCCESS_FLAG_ID = 'generalSettingsUpdateSuccess';
const ERROR_FLAG_ID = 'generalSettingsUpdateError';

export const useSettings = (enabled?: Ref<boolean>) => {
  const queryClient = useQueryClient();
  const { addFlag, dismissFlags } = useFlagsStore();

  const query = useQuery({
    queryKey: [QueryKey.SETTINGS],
    queryFn: () => api.getSettings(),
    staleTime: DEFAULT_STALE_TIME_IN_MS,
    enabled: enabled ?? true,
  });

  const mutation = useMutation({
    mutationFn: ({ settings, id }: { settings: SettingsRequest; id: number | string }) =>
      api.updateSettings(settings, id),
    onMutate: () => {
      dismissFlags([SUCCESS_FLAG_ID, ERROR_FLAG_ID]);
    },
    onSuccess: (updatedSettings) => {
      queryClient.setQueryData([QueryKey.SETTINGS], updatedSettings);

      addFlag({
        id: SUCCESS_FLAG_ID,
        type: 'success',
        title: 'Settings updated successfully',
        description: 'Your settings have been updated successfully.',
      });
    },
    onError: (error, params) => {
      addFlag({
        id: ERROR_FLAG_ID,
        type: 'error',
        title: 'Settings failed to update',
        description: 'An unexpected error occurred while updating your settings.',
        autoDismiss: false,
        error,
        actions: [
          {
            content: 'Try again',
            onClick: () => {
              mutation.mutate(params);
            },
          },
        ],
      });
    },
  });

  return {
    query,
    mutation,
  };
};
