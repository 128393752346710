import type { DetailsResponse } from '@/modules/auth/types/details';

export const identifyHelpScout = (data: DetailsResponse) => {
  window.Beacon('identify', {
    'install-url': data.install_url,
    'num-assets': data.num_assets,
    'num-asset-types': data.num_asset_types,
    'num-locations': data.num_locations,
    'num-users': data.num_users,
    'install-dt': data.created_at,
  });
};
