<script setup lang="ts">
import { provide } from 'vue';
import { RouterView } from 'vue-router';
import store from '@/store';
import { AtlasAppProvider } from '#atlaskit';
import AuthWrapper from './modules/auth/components/AuthWrapper.vue';
import AppWrapper from './modules/common/components/AppWrapper.vue';
import FlagsContainer from './modules/common/components/FlagsContainer.vue';

provide('store', store);
</script>

<template>
  <AtlasAppProvider>
    <AuthWrapper>
      <RouterView v-slot="{ Component, route }">
        <AppWrapper
          v-if="route.meta.appWrapper"
          :contained="!!route.meta.contained"
          :sidebar="!route.meta.noSidebar"
        >
          <component
            :is="Component"
            :key="`${route.path}-component`"
          />
        </AppWrapper>
        <component
          :is="Component"
          v-else
          :key="`${route.path}-component`"
        />
      </RouterView>
    </AuthWrapper>

    <FlagsContainer />
  </AtlasAppProvider>
</template>

<style>
#ac-content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
