import { fetcher } from '@/modules/common/fetch';
import { type AuthResponse } from '../types/auth';
import type { CurrentUser } from '../types/currentUser';
import type { DetailsResponse } from '../types/details';

export const api = {
  authenticate: (accountId: string) => {
    const searchParams = new URLSearchParams(window.location.search);

    return fetcher.get<AuthResponse>(`/auth/getting-started?${searchParams.toString()}`, {
      Authorization: '',
      'X-Atlassian-User': accountId,
    });
  },
  getCurrentUser: async () => {
    const { AP } = window;
    if (typeof AP === 'undefined' || typeof AP.request !== 'function') {
      return;
    }

    const data = await AP.request('/rest/api/3/myself?expand=groups');
    return JSON.parse(data.body) as CurrentUser;
  },
  getDetails: () => {
    return fetcher.get<DetailsResponse>('details');
  },
  postInstall: () => {
    return fetcher.post('details');
  },
};
