import type { RouteRecordRaw } from 'vue-router';
import { RouteName } from './constants';

export const routes: RouteRecordRaw[] = [
  {
    path: '/accessories',
    component: () => import('./views/AccessoriesHomeView'),
    name: RouteName.HOME,
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/accessories/fields',
    component: () => import('./views/FieldsView'),
    name: RouteName.FIELDS,
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/accessories/:id',
    name: RouteName.DETAILS,
    component: () => import('./views/DetailsAccessoryView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
    props: true,
  },
  {
    path: '/accessories/new',
    name: RouteName.CREATE,
    component: () => import('./views/CreateAccessoryView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/accessories/:id/edit',
    name: RouteName.EDIT,
    component: () => import('./views/EditAccessoryView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
    props: true,
  },
];
