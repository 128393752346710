import { fetcher } from '@/modules/common/fetch';
import type { SettingsResponse, SettingsRequest, JiraProjectsResponse, JiraGroupsResponse } from '../types';

export const settingsApi = {
  getSettings: () => {
    return fetcher.get<SettingsResponse>('settings');
  },
  updateSettings: (settings: SettingsRequest, id: number | string) => {
    return fetcher.put<SettingsResponse>(`settings/${id}`, settings);
  },
  getJiraProjects: () => {
    return fetcher.get<JiraProjectsResponse>('jira-projects');
  },
  getJiraGroups: () => {
    return fetcher.get<JiraGroupsResponse>('jira-groups');
  },
};
