export enum RouteName {
  HOME = 'asset-types-home',
  CREATE = 'asset-types-create',
  EDIT = 'asset-types-edit',
}

export enum QueryKey {
  ASSET_TYPES = 'asset-types',
  ASSET_TYPE = 'asset-type',
  ASSET_FIELDS = 'asset-fields',
}

export const ASSET_FIELD_NAME_MAX_LENGTH = 256;
export const ASSET_TYPE_NAME_MAX_LENGTH = 256;

export const RESERVED_FIELD_NAMES = ['id', 'name', 'asset type', 'location', 'loanee'];

export enum AssetFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  DROPDOWN = 'dropdown',
}
