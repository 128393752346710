import type { DetailsResponse } from '@/modules/auth/types/details';
import LogRocket from 'logrocket';

export const identifyLogRocket = (data: DetailsResponse) => {
  const createdAt = new Date(data.created_at * 1000);
  const createdAtFormatted = createdAt.toDateString();

  LogRocket.identify(data.install_id.toString(), {
    release: import.meta.env.VITE_APP_RELEASE,
    name: data.install_url,
    app: 'main',
    assets: data.num_assets,
    assetTypes: data.num_asset_types,
    locations: data.num_locations,
    users: data.num_users,
    createdAt: createdAtFormatted,
  });
};
