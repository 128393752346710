import type { RouteRecordRaw } from 'vue-router';
import { RouteName } from './constants';

export const routes: RouteRecordRaw[] = [
  {
    path: '/licenses',
    name: RouteName.HOME,
    component: () => import('./views/HomeView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/licenses/:id',
    name: RouteName.DETAILS,
    component: () => import('./views/LicenseDetailsView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
    props: true,
  },
  {
    path: '/licenses/new',
    name: RouteName.CREATE,
    component: () => import('./views/CreateLicenseView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/licenses/:id/edit',
    name: RouteName.EDIT,
    component: () => import('./views/EditLicenseView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
    props: true,
  },
  {
    path: '/licenses/fields',
    name: RouteName.FIELDS,
    component: () => import('./views/FieldsView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
];
