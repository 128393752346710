<script setup lang="ts">
import WarningIcon from '@atlaskit/icon/glyph/warning';
import type { ComponentProps } from 'react';
import { applyPureReactInVue } from 'veaury';
import AtlasIconContainer from './AtlasIconContainer.vue';

defineProps<{
  size?: ComponentProps<typeof WarningIcon>['size'];
  primaryColor?: ComponentProps<typeof WarningIcon>['primaryColor'];
  secondaryColor?: ComponentProps<typeof WarningIcon>['secondaryColor'];
  label?: ComponentProps<typeof WarningIcon>['label'];
}>();

const AtlasWarningIcon = applyPureReactInVue(WarningIcon);
</script>

<template>
  <AtlasIconContainer>
    <AtlasWarningIcon
      :size="size"
      :primaryColor="primaryColor"
      :secondaryColor="secondaryColor"
      :label="label"
    />
  </AtlasIconContainer>
</template>
