import type { Router } from 'vue-router';

export const manageErrors = (router: Router) => {
  // Force reload the page when we try to reload a non-existent chunk. This should
  // only happen after an update, if the previous chunk was cached by the users
  // browser.
  router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      window.location.assign(to.fullPath);
    }
  });
};
