export enum QueryKey {
  AUDIT_LOGS = 'audit-logs',
}

export enum RouteName {
  HOME = 'audit-logs-home',
}

export enum ColumnKey {
  USER = 'user',
  DATE = 'date',
  ACTION = 'action',
}
