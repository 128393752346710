<script setup lang="ts">
import ProgressBar from '@atlaskit/progress-bar';
import type { ComponentProps } from 'react';
import { applyPureReactInVue } from 'veaury';
import { computed } from 'vue';

const props = defineProps<{
  indeterminate?: ComponentProps<typeof ProgressBar>['isIndeterminate'];
  ariaLabel?: ComponentProps<typeof ProgressBar>['ariaLabel'];
  value?: ComponentProps<typeof ProgressBar>['value'];
  appearance?: ComponentProps<typeof ProgressBar>['appearance'];
}>();

const isIndeterminate = computed(() => (props.value !== undefined ? false : (props.indeterminate ?? true)));

const AtlasProgressBar = applyPureReactInVue(ProgressBar);
</script>

<template>
  <div
    class="progress-bar-container"
    :class="{ indeterminate: isIndeterminate }"
  >
    <AtlasProgressBar
      :ariaLabel="ariaLabel"
      :value="value"
      :appearance="appearance"
      :isIndeterminate="isIndeterminate"
    />
  </div>
</template>

<style scoped>
/**
 * temporal while Atlaskit fixes the bug with the progress bar animation
 */
@keyframes increasing-bar-animation {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}

@keyframes decreasing-bar-animation {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}

.progress-bar-container.indeterminate :deep([role='progressbar'] > span:first-child) {
  animation: increasing-bar-animation 2s infinite;
}

.progress-bar-container.indeterminate :deep([role='progressbar'] > span:last-child) {
  animation: decreasing-bar-animation 2s 0.5s infinite;
}
</style>
