<template>
  <div class="icon-container">
    <slot />
  </div>
</template>

<style scoped>
.icon-container >>> div {
  display: flex !important;
}
</style>
