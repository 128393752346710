import LogRocket from 'logrocket';
import * as Sentry from '@sentry/vue';

export const initializeLogRocket = () => {
  LogRocket.init(import.meta.env.VITE_APP_LOGROCKET_ID);

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.setTag('sessionURL', sessionURL);

    window.Beacon('session-data', {
      ...window.Beacon('info')?.visitor.sessionData,
      'Session URL': sessionURL,
    });
  });
};
