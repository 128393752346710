<template>
  <TransitionRoot
    as="template"
    :show="sidebarOpen"
  >
    <Dialog
      as="div"
      static
      class="fixed inset-0 flex z-40 lg:hidden"
      :open="sidebarOpen"
      @close="sidebarOpen = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="sidebarOpen = false"
              >
                <span class="sr-only">Close sidebar</span>
                <XIcon
                  class="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </button>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 flex items-center px-4">
            <img
              class="h-8 w-auto"
              src="/logo.png"
              alt="Asset Management"
            />
          </div>
          <div class="mt-5 flex-1 h-0 overflow-y-auto">
            <nav class="px-2">
              <div class="space-y-1">
                <router-link
                  v-for="(item, index) in navigation"
                  :key="index"
                  v-slot="{ href, navigate }"
                  :to="item.router"
                  custom
                >
                  <a
                    :href="getComponentURL(href)"
                    :class="[
                      item.name === getCurrentRoute()
                        ? 'bg-gray-200 text-gray-900'
                        : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                    ]"
                    @click="navigate"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        item.name === getCurrentRoute()
                          ? 'text-gray-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </a>
                </router-link>
              </div>
            </nav>
          </div>
        </div>
      </TransitionChild>
      <div
        class="flex-shrink-0 w-14"
        aria-hidden="true"
      >
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>

  <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
    <button
      class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      @click="sidebarOpen = true"
    >
      <span class="sr-only">Open sidebar</span>
      <MenuIcon
        class="h-6 w-6"
        aria-hidden="true"
      />
    </button>
  </div>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:flex lg:flex-shrink-0">
    <div class="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100">
      <div class="mx-auto flex items-center flex-shrink-0 px-6">
        <img
          class="h-8 w-auto"
          src="/logo.png"
          alt="Asset Management"
        />
      </div>
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="h-0 flex-1 flex flex-col overflow-y-auto">
        <!-- Navigation -->
        <nav class="px-3 mt-6">
          <div class="space-y-1">
            <template
              v-for="(item, index) in navigation"
              :key="index"
            >
              <router-link
                v-slot="{ href, navigate }"
                :to="item.router"
                custom
              >
                <a
                  :href="getComponentURL(href)"
                  :class="[
                    item.name === getCurrentRoute()
                      ? 'bg-gray-200 text-gray-900'
                      : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  ]"
                  @click="navigate"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.name === getCurrentRoute()
                        ? 'text-gray-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </a>
              </router-link>
            </template>
          </div>
          <div class="flex-shrink-0 flex border-t border-gray-200 p-2 mt-3 space-y-1">
            <a
              id="whats-new-link"
              href="#"
              class="flex mt-2"
            >
              <BellIcon class="text-gray-400 h-6 w-6" />
              <p class="text-gray-500 text-sm font-medium rounded-md ml-3">What's new</p>
            </a>
          </div>
        </nav>
      </div>
    </div>

    <LaunchNotes />
  </div>
</template>

<script>
import { ref } from 'vue';
import {
  BellIcon,
  CogIcon,
  DesktopComputerIcon,
  DocumentSearchIcon,
  LocationMarkerIcon,
  ExclamationCircleIcon,
  CollectionIcon,
  MenuIcon,
  MenuAlt1Icon,
  PrinterIcon,
  XIcon,
  DocumentReportIcon,
  UsersIcon,
  ViewGridIcon,
} from '@heroicons/vue/outline';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  SearchIcon,
  SelectorIcon,
  // DocumentReportIcon,
} from '@heroicons/vue/solid';
import { useRouter } from 'vue-router';
import utils from '@/utils';
import LaunchNotes from '@/modules/monitoring/components/LaunchNotes.vue';

const navigation = [
  {
    name: 'Assets',
    icon: DesktopComputerIcon,
    router: '/assets',
    current: true,
  },
  {
    name: 'Locations',
    icon: LocationMarkerIcon,
    router: '/locations',
    current: false,
  },
  {
    name: 'Asset Types',
    icon: CollectionIcon,
    router: '/asset-types',
    current: false,
  },
  {
    name: 'Accessories',
    icon: PrinterIcon,
    router: '/accessories',
    current: false,
  },
  {
    name: 'Licenses',
    icon: ViewGridIcon,
    router: '/licenses',
    current: false,
  },
  {
    name: 'Alerts',
    icon: ExclamationCircleIcon,
    router: '/alerts',
    current: false,
  },
  {
    name: 'Users',
    icon: UsersIcon,
    router: '/loanees',
    current: false,
  },
  {
    name: 'Reports',
    icon: DocumentReportIcon,
    router: '/reports',
    current: false,
  },
  {
    name: 'Settings',
    icon: CogIcon,
    router: '/settings',
    current: false,
  },
  {
    name: 'Audit Logs',
    icon: DocumentSearchIcon,
    router: '/audit-logs',
    current: false,
  },
];

if (import.meta.env.VITE_APP_ENV === 'development') {
  navigation.push({
    name: 'New Onboard flow',
    icon: DocumentSearchIcon,
    router: '/onboarding',
    current: false,
  });
}

export default {
  components: {
    BellIcon,
    Dialog,
    DialogOverlay,
    Menu,
    MenuIcon,
    MenuAlt1Icon,
    MenuButton,
    MenuItem,
    MenuItems,
    SearchIcon,
    SelectorIcon,
    TransitionChild,
    TransitionRoot,
    XIcon,
    DocumentReportIcon,
    LaunchNotes,
  },
  setup() {
    const router = useRouter();

    const getCurrentRoute = () => {
      const routeName = router.currentRoute.value.name?.toString();

      if (
        routeName?.startsWith('Accessories') ||
        routeName?.startsWith('Accessory') ||
        routeName?.startsWith('accessories')
      ) {
        return 'Accessories';
      } else if (routeName?.startsWith('Location') || routeName?.startsWith('locations')) {
        return 'Locations';
      } else if (routeName?.startsWith('Alert') || routeName?.startsWith('alerts')) {
        return 'Alerts';
      } else if (routeName?.startsWith('asset-types')) {
        return 'Asset Types';
      } else if (routeName?.startsWith('Settings') || routeName?.startsWith('settings')) {
        return 'Settings';
      } else if (routeName?.startsWith('Report') || routeName?.startsWith('reports')) {
        return 'Reports';
      } else if (routeName?.startsWith('users')) {
        return 'Users';
      } else if (routeName?.startsWith('License') || routeName?.startsWith('licenses')) {
        return 'Licenses';
      } else if (routeName?.startsWith('audit-logs')) {
        return 'Audit Logs';
      } else return 'Assets';
    };

    const sidebarOpen = ref(false);

    const getComponentURL = (componentPath) => {
      return utils.getAtlassianComponentURL(componentPath);
    };

    return {
      navigation,
      sidebarOpen,
      getCurrentRoute,
      getComponentURL,
    };
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}

p {
  margin-block: 0;
}
</style>
