import { createApp } from 'vue';
import '@Styles/main.css';
import App from './App.vue';
import Libraries from '@Libraries';
import Plugins from '@Plugins';
import { VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query';
import { initializeMonitoring } from './modules/monitoring';
import { FetchError } from './modules/common/fetch';
import RouterPrefetch from 'vue-router-prefetch';
import router from './modules/common/router';

const clickOutside = {
  // eslint-disable-next-line
  beforeMount: (el: HTMLInputElement, binding: any) => {
    // @ts-expect-error custom event
    // eslint-disable-next-line
    el.clickOutsideEvent = (event: any) => {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    // @ts-expect-error custom even defintion
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: (el: HTMLInputElement) => {
    // @ts-expect-error custom event definition
    document.removeEventListener('click', el.clickOutsideEvent);
  },
};

const app = createApp(App);
app.use(router);
app.use(RouterPrefetch);
initializeMonitoring(app, router);

const vueQueryPLuginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        retry: (failureCount, error) => {
          if (failureCount >= 2) return false;
          if (error instanceof FetchError) {
            if (!error.response?.status) {
              return false;
            }

            const { status } = error.response;
            const isClientError = status >= 400 && status < 500;
            if (isClientError) return false;
          }

          return true;
        },
      },
    },
  },
};
app.use(VueQueryPlugin, vueQueryPLuginOptions);

Libraries.forEach(({ lib, options }) => app.use(lib, options));
Plugins.forEach((plugin) => app.use(plugin));

app.directive('click-outside', clickOutside);
app.mount('#app');
