import { fetcher } from '@/modules/common/fetch';
import type { CustomFieldsResponse, CustomFieldRequest, CustomField } from '../types';

export const customFieldsApi = {
  getCustomFields: () => {
    return fetcher.get<CustomFieldsResponse>('custom-fields');
  },
  createCustomField: (customField: CustomFieldRequest) => {
    return fetcher.post<CustomField>('custom-fields', customField);
  },
  updateCustomField: (customField: CustomFieldRequest, id: number | string) => {
    return fetcher.put<CustomField>(`custom-fields/${id}`, customField);
  },
  deleteCustomField: (id: number | string) => {
    return fetcher.delete(`custom-fields/${id}`);
  },
  syncCustomFields: () => {
    return fetcher.post('custom-fields/update-issues');
  },
};
