import type { RouteRecordRaw } from 'vue-router';
import routesToBeMigrated from './to-be-migrated-routes';
import { AssetRoutes } from '@AssetModule';
import { assetsRoutes } from '#assets';
import { licensesRoutes } from '#licenses';
import { accessoriesRoutes } from '#accessories';
import { usersRoutes } from '#users';
import { onboardingRoutes } from '#onboarding';
import { routes as settingsRoutes } from '#settings/routes';
import { routes as locationsRoutes } from '#locations/routes';
import { routes as auditLogsRoutes } from '#audit-logs/router';
import { routes as assetTypesRoutes } from '#asset-types/router';
import { routes as reportsRoutes } from '#reports/routes';
import { routes as alertsRoutes } from '#alerts/routes';

export const ROUTES: Array<RouteRecordRaw> = [
  ...routesToBeMigrated,
  ...AssetRoutes,
  ...assetsRoutes,
  ...licensesRoutes,
  ...accessoriesRoutes,
  ...usersRoutes,
  ...onboardingRoutes,
  ...reportsRoutes,
  ...settingsRoutes,
  ...auditLogsRoutes,
  ...locationsRoutes,
  ...assetTypesRoutes,
  ...alertsRoutes,
];
