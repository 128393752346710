import type { Router } from 'vue-router';
import { canUse } from '@/modules/monitoring/posthog/featureFlags';
import { FeatureFlag } from '@/modules/monitoring/posthog/constants';

export const manageFeatureFlags = (router: Router) => {
  router.beforeEach((to, _, next) => {
    if (to.path === '/assets' || to.path === '/assets-new') {
      canUse(FeatureFlag.ASSET_HOME_NEW, (can) => {
        if (can && to.path === '/assets') return next('/assets-new');
        if (!can && to.path === '/assets-new') return next('/assets');

        return next();
      });
    } else if (to.path === '/assets/import' || to.path === '/assets/import-new') {
      canUse(FeatureFlag.ASSET_IMPORTS_NEW, (can) => {
        if (can && to.path === '/assets/import') return next('/assets/import-new');
        if (!can && to.path === '/assets/import-new') return next('/assets/import');

        return next();
      });
    } else {
      return next();
    }
  });
};
