import * as Sentry from '@sentry/vue';
import type { PostHog } from 'posthog-js';
import type { App } from 'vue';
import type { Router } from 'vue-router';

export const initializeSentry = (app: App, router: Router, posthog: PostHog) => {
  Sentry.init({
    app,
    release: import.meta.env.VITE_APP_RELEASE,
    environment: import.meta.env.VITE_APP_ENV,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.browserProfilingIntegration(),
      posthog.sentryIntegration({
        organization: import.meta.env.VITE_APP_SENTRY_ORGANIZATION,
        projectId: import.meta.env.VITE_APP_SENTRY_PROJECT_ID,
      }),
    ],
    tracesSampleRate: Number(import.meta.env.VITE_APP_SENTRY_TRACING_RATE),
    tracePropagationTargets: ['app.assetmanagementforjira.com'],
    profilesSampleRate: Number(import.meta.env.VITE_APP_SENTRY_PROFILING_RATE),
  });
};
