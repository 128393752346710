import type { FeatureFlag } from './constants';
import posthog from './instance';

export const canUse = (featureFlag: FeatureFlag, callback: (can: boolean | undefined) => void) => {
  if (posthog.featureFlags.hasLoadedFlags) {
    callback(posthog.isFeatureEnabled(featureFlag));
  } else {
    posthog.onFeatureFlags(() => {
      callback(posthog.isFeatureEnabled(featureFlag));
    });
  }
};
