import { useJWT } from '#auth/composables/useJWT';
import { useMeta } from '#auth/composables/useMeta';
import { FETCH_TIMEOUT, FetchMethod } from './constants';
import { FetchError } from './error';
import type { FetchOptions } from './types';

const BASE_URL = import.meta.env.VITE_APP_URL;

const constructUrl = (path: string) => {
  if (path.startsWith('/')) {
    return new URL(path, BASE_URL).toString();
  }

  return new URL(`/api/${path}`, BASE_URL).toString();
};

const deconstructUrl = (url: string) => {
  const urlObject = new URL(url);
  const path = urlObject.pathname.replace('/api', '');
  return path;
};

const makeFetch = async (url: string, options: FetchOptions) => {
  const { getToken } = useJWT();
  const { getMeta } = useMeta();

  try {
    const response = await fetch(url, {
      method: options.method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        'X-Atlassian-User': getMeta().atlassianId,
        'X-Atlassian-Username': encodeURIComponent(getMeta().atlassianUsername),
        ...options.headers,
      },
      signal: AbortSignal.timeout(FETCH_TIMEOUT),
      body: JSON.stringify(options.body),
    });

    if (response.ok) return response;

    if (response.status === 401) {
      // TODO: implement refresh token logic
    } else if (response.status === 404) {
      throw new FetchError('Resource Not Found', response);
    }

    const isServerError = response.status >= 500 && response.status <= 599;
    if (isServerError) {
      throw new FetchError(
        `Server Error: ${response.status} | ${options.method} ${deconstructUrl(url)}`,
        response
      );
    }

    let data;
    try {
      data = await response.json();
    } catch (error) {
      throw new FetchError('Failed to fetch', response);
    }

    if (data.message) {
      throw new FetchError(data.message, response);
    } else {
      throw new FetchError(JSON.stringify(data), response);
    }
  } catch (error) {
    if (error instanceof DOMException && error.name === 'TimeoutError') {
      throw new FetchError(
        'The request took too long to complete. Please check your internet connection and try again'
      );
    }
    throw error;
  }
};

export const fetcher = {
  get: async <T>(endpoint: string, headers?: FetchOptions['headers']) => {
    const url = constructUrl(endpoint);
    const response = await makeFetch(url, { method: FetchMethod.GET, headers });
    const data = await response.json();
    return data as T;
  },
  post: async <T>(endpoint: string, body?: any, headers?: FetchOptions['headers']) => {
    const url = constructUrl(endpoint);
    const response = await makeFetch(url, { method: FetchMethod.POST, body, headers });
    if (response.status === 204) return null as T;

    const data = await response.json();
    return data as T;
  },
  put: async <T>(endpoint: string, body: any, headers?: FetchOptions['headers']) => {
    const url = constructUrl(endpoint);
    const response = await makeFetch(url, { method: FetchMethod.PUT, body, headers });
    const data = await response.json();
    return data as T;
  },
  delete: async (endpoint: string, headers?: FetchOptions['headers']) => {
    const url = constructUrl(endpoint);
    const response = await makeFetch(url, { method: FetchMethod.DELETE, headers });
    return response;
  },
};
