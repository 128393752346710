import { useMutation, useQuery } from '@tanstack/vue-query';
import { QueryKey } from '../constants';
import { api } from '../api';
import { DEFAULT_STALE_TIME_IN_MS } from '@/modules/common/constants/queries';
import { computed, watch } from 'vue';
import { useJWT } from './useJWT';
import { identifyMonitoring } from '@/modules/monitoring';

const ASSETS_PER_PAGE = 50;

export const useDetails = () => {
  const { hasToken } = useJWT();

  const query = useQuery({
    queryKey: [QueryKey.DETAILS],
    queryFn: () => api.getDetails(),
    staleTime: DEFAULT_STALE_TIME_IN_MS,
    enabled: hasToken,
  });

  watch(query.data, (data) => {
    if (data) identifyMonitoring(data);
  });

  const assetsPagination = computed(() => {
    if (!query.data.value) return null;

    return {
      assets: query.data.value.num_assets,
      pageSize: ASSETS_PER_PAGE,
      pages: Math.ceil(query.data.value.num_assets / ASSETS_PER_PAGE),
    };
  });

  const postInstallMutation = useMutation({
    mutationFn: () => api.postInstall(),
  });

  return { query, assetsPagination, postInstallMutation };
};
