import type { Router } from 'vue-router';
import posthog from './instance';

export const initializePostHog = (router: Router) => {
  // helps loading flags a bit faster
  posthog.featureFlags.ensureFlagsLoaded();

  router.afterEach(() => {
    posthog.capture('$pageview');
  });

  router.beforeEach((to, from) => {
    if (from.path !== to.path) posthog.capture('$pageleave');
  });

  return posthog;
};
